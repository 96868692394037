import styled from 'styled-components';

import { RESPONSIVE_VALUE } from './../../config/constants';
import {
  font,
  headerLinkColor,
  secondaryColor,
} from './../../config/theme/index';
import { Props } from '.';

const RESPONSIVE = '1030px';

export const Container = styled.header`
  width: 100%;
  max-width: 100%;
  height: 120px;

  padding: 30px;

  background-color: #0d1d36;

  @media (max-width: ${RESPONSIVE}) {
    display: flex;

    align-items: center;
  }

  @media only screen and (max-width: 308px) {
    padding: 15px;
  }
`;

export const Logo = styled.img`
  width: 196px;
  height: 74px;
`;

export const PersonWelcome = styled.div`
  display: flex;
  flex-flow: column;

  height: 100%;

  font-size: 15px;
  font-weight: normal;
  font-family: ${font};
  font-stretch: normal;
  font-style: normal;

  text-align: right;
  line-height: 1.35;
  letter-spacing: normal;

  color: ${headerLinkColor};

  p {
    font-size: 20px;
    font-weight: bold;
  }
`;

export const ExitButton = styled.button`
  font-family: ${font};
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;

  text-align: right;
  line-height: 1.33;
  letter-spacing: normal;

  color: var(--softBlue);

  cursor: pointer;

  border: none;

  background: none;
`;

export const OpenCloseResponsiveMenu = styled.button`
  display: none;

  width: 45px;
  height: 45px;

  margin-left: 20px;

  border: none;

  background: none;

  svg {
    width: 45px;
    height: 45px;
  }

  &:focus {
    outline: none;
  }

  @media (max-width: ${RESPONSIVE}) {
    display: block;
  }

  @media only screen and (max-width: 308px) {
    margin-left: 0px;
  }
`;

export const List = styled.ul<Props>`
  display: flex;

  width: ${({ width }) => width || '100%'};
  max-width: 100%;
  height: 100%;

  justify-content: space-between;
  align-items: center;

  li {
    display: flex;

    ${ExitButton} {
      margin-left: 20px;
    }

    @media (max-width: ${RESPONSIVE}) {
      display: none;

      &.not-hide {
        display: flex;
      }
    }
  }
`;

export const Overlay = styled.div<{ active: boolean }>`
  z-index: 10;

  position: fixed;

  top: 0;
  right: 0;

  display: ${props => (props.active ? 'flex' : 'none')};

  width: 100%;
  height: 100%;

  overflow: hidden;

  justify-content: center;

  background: rgba(0, 0, 0, 0.55);

  transition: all ease 0.3s;
`;

export const ResponsiveMenu = styled.div<{ active: boolean }>`
  z-index: 999999;

  position: fixed;

  display: flex;
  flex-flow: wrap;

  width: ${props => (props.active ? '30%' : '0')};
  height: 100%;

  top: 0;
  right: 0;

  overflow: hidden;

  justify-content: flex-start;

  background: ${secondaryColor};

  transition: all ease 0.3s;

  ${List} {
    flex-flow: wrap column;

    justify-content: center;

    opacity: ${props => (props.active ? '100%' : '0')};

    transition: all ease 0.5s;

    li {
      display: block;

      margin-bottom: 20px;

      a {
        font-size: 16px;

        &.active {
          font-size: 16px;
        }
      }
    }

    ${ExitButton} {
      margin-left: 0;
    }
  }

  .menu-header {
    display: flex;

    width: 100%;

    justify-content: center;

    ${OpenCloseResponsiveMenu} {
      position: absolute;

      width: 30px;
      height: 30px;

      margin-top: 40px;
      margin-left: 0;

      svg {
        width: 30px;
        height: 30px;
      }
    }
  }

  @media (max-width: ${RESPONSIVE_VALUE}) {
    width: ${props => (props.active ? '80%' : '0')};
  }
`;

