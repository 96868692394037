import './assets/reset.scss';
import 'primereact/resources/themes/md-dark-indigo/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import './assets/imports.scss';
import 'react-simple-hook-modal/dist/styles.css';
import 'primeflex/primeflex.css';

import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ModalProvider } from 'react-simple-hook-modal';

import Loader from './components/Loader';
import PrivateRoute from './components/PrivateRoute';
import GlobalStyles from './config/globalStyle';
import Painel from './containers/Painel';
import NotFound from './containers/public/NotFound';
import publicRoutes from './containers/public/routes';

function App() {
  return (
    <Suspense fallback={<Loader />}>
      <ModalProvider>
        <GlobalStyles />
        <Router>
          <Switch>
            {publicRoutes.map((route, index) => (
              <Route key={index} path={route.path} exact={route.exact}>
                <route.component />
              </Route>
            ))}

            <PrivateRoute path='/painel'>
              <Painel />
            </PrivateRoute>

            <Route path='*'>
              <NotFound />
            </Route>
          </Switch>
        </Router>
      </ModalProvider>
    </Suspense>
  );
}

export default App;
